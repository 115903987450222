<script setup lang="ts">
import { formatDistanceToNow } from 'date-fns';
import AppSpellIcon from '~/components/app/AppSpellIcon.vue';
import EmptyState from '~/components/table/EmptyState.vue';
import type { Filter, Pagination } from '~/util/filters';
import { formatCurrency } from '~/util/formats';

interface Props {
  fields?: string[];
  hidePagination?: boolean;
  pageSize: number;
  fromNow?: boolean;
  filters?: Filter;
}

const props = defineProps<Props>();

const { fields, pageSize, hidePagination = false, fromNow = false } = props;

const agentStore = useAgentsStore();
const workspaceStore = useWorkspaceStore();

const { routeName } = useRouteName();
const spellId = useRouteParams('spellId');
const activeRunId = useRouteParams('runId');

const { workspaceId } = storeToRefs(workspaceStore);

// When filters change, we need to reset `page`
watch([props.filters], () => {
  page.value = 1;
});

const page = ref(1);
const pagination: Pagination = computed(() => ({
  take: pageSize,
  skip: (page.value - 1) * pageSize,
}));

const { data, pending, error } = await useApi('/api/graphRuns', {
  query: {
    workspaceId,
    spellId,
    pagination,
    filters: props.filters,
    agentId:
      routeName.value === 'agent.analytics' ? agentStore.agentSpellId : null,
  },
});

const columns = computed(() => {
  const allColumns = [
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'status',
      label: 'Status',
    },
    {
      key: 'startedAt',
      label: 'Started At',
    },
    {
      key: 'runtime',
      label: 'Runtime',
    },
    {
      key: 'cost',
      label: 'Cost',
    },
    {
      key: 'result',
    },
  ];

  return fields
    ? allColumns.filter((column) => fields.includes(column.key))
    : allColumns;
});

const rows = computed(() =>
  data.value?.graphRuns.map((graphRun) => ({
    id: graphRun.id,
    spellId: graphRun.spellId,
    spell: graphRun.spell,
    name: graphRun.spell.name,
    status: graphRun.state,
    scheduleId: graphRun.scheduleId,
    groupId: graphRun.groupId,
    triggered: graphRun.graph.inputType === 'triggered',
    startedAt: fromNow
      ? `${formatDistanceToNow(graphRun.createdAt)} ago`
      : formatDate(graphRun.createdAt),
    runtime: formatRuntime(graphRun.createdAt, graphRun.endedAt),
    cost: formatCurrency(graphRun.cost, 4),
    class: activeRunId.value === graphRun.id ? 'bg-gray-100' : '',
  })),
);

const formatDate = (startedAt: string): string => {
  return new Date(startedAt).toLocaleDateString('en-us', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
};

const formatRuntime = (startedAt: string, endedAt: string): string => {
  const startTime = new Date(startedAt).getTime();
  const endTime = (endedAt ? new Date(endedAt) : new Date()).getTime(); // Might not be ended yet

  let seconds = Math.ceil((endTime - startTime) / 1000);
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;

  return minutes
    ? `${minutes}m ${Math.ceil(seconds)}s`
    : `${Math.ceil(seconds)}s`;
};

async function openSpellRun(row) {
  await navigateTo({
    name: routeName.value as string,
    params: { runId: row.id, spellId: spellId.value, agentId: 'sdr' },
    replace: true,
  });
}

const statusToBadge = {
  inProgress: {
    label: 'In Progress',
    color: 'orange',
  },
  paused: {
    label: 'Paused',
    color: 'gray',
  },
  success: {
    label: 'Success',
    color: 'green',
  },
  error: {
    label: 'Error',
    color: 'red',
  },
  canceled: {
    label: 'Canceled',
    color: 'gray',
  },
  skipped: {
    label: 'Skipped',
    color: 'gray',
  },
};
</script>
<template>
  <UTable
    :columns="columns"
    :loading="pending"
    :loading-state="{
      icon: 'i-ph-arrows-clockwise',
      label: 'Loading...',
    }"
    :rows="rows"
    class="w-full !mt-0"
    :ui="{
      tr: {
        base: 'truncate',
      },
    }"
    @select="openSpellRun"
  >
    <template #empty-state>
      <empty-state :class="hidePagination ? '!py-10' : ''">
        <p class="text-lg font-bold">No spell runs yet</p>
        <p class="text-lg dimmed">
          Run your first spell from the Spells or Templates page
        </p>
        <div class="flex py-s gap-4">
          <UButton
            icon="i-ph-stack"
            type="button"
            size="xl"
            color="white"
            @click="navigateTo('/templates')"
          >
            See Templates
          </UButton>
          <UButton
            icon="i-ph-plus"
            type="button"
            size="xl"
            @click="navigateTo('/spells')"
          >
            New Spell
          </UButton>
        </div>
      </empty-state>
    </template>

    <template #name-data="{ row }">
      <div class="flex flex-row gap-2 justify-start items-start">
        <AppSpellIcon :spell="row.spell" />
        <p class="truncate">{{ row.name }}</p>
        <UBadge
          v-if="row.scheduleId"
          label="Scheduled"
          color="gray"
          variant="soft"
        />
        <UBadge
          v-else-if="row.groupId"
          label="Bulk Run"
          color="gray"
          variant="soft"
        />
        <UBadge
          v-else-if="row.triggered"
          label="Triggered"
          color="gray"
          variant="soft"
        />
      </div>
    </template>
    <template #status-data="{ row }">
      <UBadge
        size="xs"
        :label="statusToBadge[row.status]?.label"
        :color="statusToBadge[row.status]?.color"
        variant="solid"
      />
    </template>
    <template #result-data>
      <UIcon name="i-ph-caret-right" />
    </template>
  </UTable>
  <div v-if="!hidePagination" class="flex justify-center px-3 py-3.5 w-full">
    <UPagination v-model="page" :page-count="pageSize" :total="data?.count" />
  </div>
</template>
